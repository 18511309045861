import React from "react";
import styles from "../Assets/css/dispacthresource.module.css"
import { Container, } from "react-bootstrap";
import topbarLogo from "../Assets/images/logo.png";

import { useEffect, useState } from "react";
import Loader from "../component/Loader"
const DispatchResource = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [deviceCounts, setDeviceCounts] = useState({});
    const connectListApi = process.env.REACT_APP_BASE_URL + 'get/texture/device/'
    const dispatchclikApi = process.env.REACT_APP_BASE_URL + 'texture/device/dispatch/'
    const globaldispatchApi = process.env.REACT_APP_BASE_URL + 'texture/device/global/dispatch/'
    const [deviceStatus, setDeviceStatus] = useState({});
    var [BatterybtnValue = 'Dispatch', setbtnValue] = useState();
    // const [BatterybtnValue, setbtnValue] = useState('Dispatch');
    var [ThermostatebtnValue = 'Dispatch', setthemostatbtnValue] = useState();
    var [VehiclebtnValue = 'Dispatch', setVehiclebtnValue] = useState();
    const [data, setData] = useState([]);
    const [thermostatCount, setThermostatCount] = useState();
    let apiStatus;
    let initialData;
    const [loading, setLoading] = useState({
        global: false,
        thermostat: false,
        battery: false,
        vehicle: false
    });


    const incrementThermostatCount = () => {
        setThermostatCount(thermostatCount + 1);
    };
    const decrementThermostatCount = () => {
        if (thermostatCount > 0) {
            setThermostatCount(thermostatCount - 1);
        }
    };
    useEffect(() => {
        const storedAccessToken = localStorage.getItem('accessToken');
        if (storedAccessToken) {
            setAccessToken(storedAccessToken);
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
          
            fetchData();
            fetchGlobalData();
        }
    }, [accessToken]);

    const RefreshAllData = async () => {
        try {
            const storedAccessToken = localStorage.getItem('accessToken');
            const response = await fetch(connectListApi, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedAccessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch  data');
            }

            const data = await response.json();
            // console.log('Thermostat data:', data);
            setData(data);
            const filteredData = data.filter(device => {
                return device.vpp_participations && device.vpp_participations.some(participation => participation.is_dispatching === true);
            });
            const deviceCounts = {};
            filteredData.forEach(device => {
                const deviceType = device.device_type.toLowerCase().replace(/\s+/g, '').replace(/^(.)/, (match, p1) => p1.toUpperCase());
                deviceCounts[deviceType] = (deviceCounts[deviceType] || 0) + 1;
            });
            setDeviceCounts(deviceCounts);
            
            console.log(deviceCounts, "devicee")
        } catch (error) {
            console.error('Error fetching  data:', error);
        }
    };
    const fetchData = async () => {
        try {
            setLoading({ ...loading, global: true });
            const response = await fetch(connectListApi, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            console.log('Data:', data);
            const filteredData = data.filter(device => {
                return device.vpp_participations && device.vpp_participations.some(participation => participation.is_dispatching === true);
            });
            const deviceCounts = {};
            filteredData.forEach(device => {
                const deviceType = device.device_type.toLowerCase().replace(/\s+/g, '').replace(/^(.)/, (match, p1) => p1.toUpperCase());
                deviceCounts[deviceType] = (deviceCounts[deviceType] || 0) + 1;
            });
            setDeviceCounts(deviceCounts);
            console.log(deviceCounts, "devicee")
            setData(data);
            console.log("filteredData", data)
            setLoading({ ...loading, global: false }); 
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const fetchGlobalData = async () => {
        try {
            const response = await fetch(globaldispatchApi, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            console.log('globalData:', data);
            setDeviceStatus(data);
            
            setbtnValue(data.dispatching_battery ? BatterybtnValue= "Dispatching" : "Dispatch");
            // if (data.hasOwnProperty('dispatching_battery')) {
            //     setbtnValue(data.dispatching_battery ? "Dispatching" : "Dispatch");
            // }

            setthemostatbtnValue(data.dispatching_thermostat ? ThermostatebtnValue = "Dispatching" : 'Dispatch');
            setVehiclebtnValue(data.dispatching_vehicle ? VehiclebtnValue = "Dispatching" : 'Dispatch');
            setThermostatCount(data.temperature)
            // console.log(data.temperature,"kjihug")
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleDispatchClick = async (deviceType) => {
        try {
            setLoading({ ...loading, global: true });
            if (deviceType === 'Inverter' ? 'Battery' : deviceType) {
                if (BatterybtnValue === 'Dispatching'){
                    apiStatus = "disable";
                }
                if (BatterybtnValue === 'Dispatch'){
                    apiStatus = "enable";
                }
                initialData = {
                    "status": apiStatus,
                    "global": true,
                    "device_type": deviceType === 'Inverter' ? 'Battery' : deviceType,
                };
            }
            if (deviceType === 'Thermostat') {
                if (ThermostatebtnValue === 'Dispatching'){
                    apiStatus = "disable";
                }
                if (ThermostatebtnValue === 'Dispatch'){
                    apiStatus = "enable";
                }
                initialData = {
                    "status": apiStatus,
                    "global": true,
                    "device_type": deviceType,
                    "temperature": thermostatCount
                };
            }
            if (deviceType === 'Vehicle') {
                if (VehiclebtnValue === 'Dispatching'){
                    apiStatus = "disable"
                }
                if (VehiclebtnValue === 'Dispatch'){
                    apiStatus = "enable";
                }
                initialData = {
                    "status": apiStatus,
                    "global": true,
                    "device_type": deviceType,
                };
            }
          
            const response = await fetch(dispatchclikApi, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(initialData)
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data. Server returned ' + response.status);
            }
            const data = await response.json(); 
            setLoading(false)
            RefreshAllData()
            fetchGlobalData()
            if (deviceType  === 'Inverter') {
                setbtnValue(BatterybtnValue = apiStatus === 'enable' ? "Dispatch" : "Dispatching" );
               
            } else if (deviceType === 'Thermostat') {
                setthemostatbtnValue(ThermostatebtnValue = apiStatus === 'enable' ? "Dispatch" : "Dispatching");

            } else if (deviceType === 'Vehicle') {
                setVehiclebtnValue(VehiclebtnValue = apiStatus === 'enable' ? "Dispatch" : "Dispatching");
            }
            

            const updatedDeviceStatus = { ...deviceStatus };
        
            updatedDeviceStatus[`dispatching_${deviceType.toLowerCase()}`] = apiStatus === 'enable' ? false : true;
            // setDeviceStatus(updatedDeviceStatus);
            console.log(updatedDeviceStatus, "hsjhhjd")
            setLoading({ ...loading, global: false }); 
        } catch (error) {
            console.error('Error:', error.message);
            setLoading({ ...loading, global: false }); 

        }
       
    };

  

   



    return (
        <div className={styles.fleet_view_cantaint}>
            {loading && loading.global && <Loader />}
            <section className={styles.topbar}>
                <div className={styles.container}>
                    <img className={styles.topbar_logo} src={topbarLogo} alt="Topbar Logo" />
                </div>
            </section>

            <section className={styles.colorLightContainer}>
                <div className={styles.dispatchResourcesParent}>
                    <h2 className={styles.dispatchResources}>Dispatch Resources</h2>

                    <div className={styles.thermostatControlParent}>

                        <div className={styles.thermostatControl}>
                            <div className={styles.termo}>
                                <div className={styles.rectangleParent}>
                                    <div className={styles.frameDiv}>
                                    <div className={styles.frameChild} />
                                    <div className={styles.deviceDispatcher}>
                                        <b className={styles.thermostat}>Thermostat</b>
                                    </div>
                                    <div className={styles.borderdiv}>

                                    </div>
                                    <div className={styles.globalStopChargingButton}>
                                        <div className={styles.maxDispatchToggle}>
                                            <div className={styles.dispatchSettings}>
                                                <div className={styles.dispatch}>
                                                    <div className={styles.maxDispatch}><p>Max Dispatch</p></div>
                                                </div>
                                                <div className={styles.globalSetPoint}>
                                                    <p> Global Set Point Toggle</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.frameParent}>
                                                <div className={styles.rectangleGroup} style={{ backgroundColor: ThermostatebtnValue === 'Dispatching' ? 'white' : '', color: ThermostatebtnValue === 'Dispatching' ? 'blue' : '' }}>
                                                    <div className={styles.frameItem} />
                                                    {loading && loading.thermostat && <Loader />}
                                                    <b className={styles.dispatch1} onClick={() => handleDispatchClick("Thermostat")}>
                                                        {ThermostatebtnValue}
                                                    </b>
                                                </div>
                                            <div className={styles.rectangleContainer}>
                                                <div className={styles.frameInner} />
                                                <div className={styles.degreesSymbolWrapper}>
                                                    <p className={styles.degreesSymbol} onClick={decrementThermostatCount}>-</p>
                                                </div>
                                                <b className={styles.b}>{thermostatCount}°</b>
                                                <div className={styles.lineWrapper}>
                                                        <div className={styles.lineDiv} />
                                                        
                                                </div>
                                                <div className={styles.devicesDispatchingLabelWrapper}>
                                                    <p className={styles.devicesDispatchingLabel} onClick={incrementThermostatCount}>+</p>
                                                </div>
                                                <div className={styles.capacityIndicatorWrapper}>
                                                    <div className={styles.capacityIndicator} />
                                                </div>
                                            </div>

                                        </div>
                                        </div>
                                    </div>
                                    <div className={styles.devicesDispatchingParent}>
                                        <div className={styles.devicesDispatching}>
                                            <div className={styles.div}> <p>Devices Dispatching</p> </div>
                                            <div className={styles.div}><p>{deviceCounts["Thermostat"] || 0}</p></div>

                                        </div>
                                        <div className={styles.degreesAlteredWrapper}>
                                            <div className={styles.degreesAltered}><p>Degrees Altered </p></div>
                                            <div className={styles.div1}><p>{deviceCounts["Thermostat"] * thermostatCount || 0}°     </p></div>
                                        </div>
                                        <div className={styles.estimatedCapacityWrapper}>
                                            <div className={styles.estimatedCapacity}>
                                                <p> Estimated Capacity</p>
                                            </div>
                                            <div className={styles.kwh}><p>{deviceCounts["Thermostat"] * .25 || 0} kWh</p></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className={styles.termo}>
                                <div className={styles.frameDiv}>
                                    <div className={styles.rectangleDiv} />
                                    <b className={styles.battery}>Battery</b>
                                    <div className={styles.borderdiv}>

                                    </div>
                                    <div className={styles.frameGroup}>
                                        <div className={styles.maxDispatchWrapper}>
                                            <div className={styles.maxDispatch1}><p>Max Dispatch</p></div>
                                        </div>
                                        <div className={styles.rectangleGroup} style={{ backgroundColor: BatterybtnValue === 'Dispatching' ? 'white' : '', color: BatterybtnValue === 'Dispatching' ? 'blue' : '' }}>
                                            <div className={styles.frameChild1} />
                                            {loading && loading.battery && <Loader />}
                                            <b
                                                className={styles.dispatch1}
                                                onClick={() => handleDispatchClick("Inverter")}
                                            >
                                                {BatterybtnValue}
                                            </b>

                                        </div>
                                    </div>


                                </div>
                                <div className={styles.devicesDispatchingGroup}>
                                    <div className={styles.devicesDispatching1}>
                                        <div className={styles.div3}><p>Devices Dispatching</p></div>
                                        <div className={styles.div3}><p>{deviceCounts["Battery"] || 0 }</p></div>
                                    </div>
                                    <div className={styles.deviceCapacityLabel}>
                                        <div className={styles.capacityDischarging}>
                                            <p> Capacity Discharging</p>
                        

                                        </div>
                                        <div className={styles.div2}><p>74</p> </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.termo}>
                                <div className={styles.rectangleParent1}>
                                    <div className={styles.frameChild2} />
                                    <b className={styles.electricVehicle}>Electric Vehicle</b>
                                    <div className={styles.borderdiv}>

                                    </div>
                                    <div className={styles.frameContainer}>
                                        <div className={styles.globalStopChargingWrapper}>
                                            <div className={styles.globalStopCharging}>
                                                <p> Global Stop Charging</p>
                                            </div>
                                        </div>
                                        <div className={styles.rectangleParent2} style={{ backgroundColor: VehiclebtnValue === 'Dispatching' ? 'white' : '', color: VehiclebtnValue === 'Dispatching' ? 'blue' : '' }}>
                                            <div className={styles.frameChild3} />
                                            {loading && loading.vehicle && <Loader />}
                                            <b className={styles.dispatch1} onClick={() => handleDispatchClick('Vehicle')}>
                                                {VehiclebtnValue}
                                            </b>

                                        </div>
                                    </div>

                                </div>
                                <div className={styles.devicesDispatchingGroup}>

                                    <div className={styles.devicesDispatching2}>
                                        <div className={styles.div3}> <p> Devices Dispatching</p></div>
                                        <div className={styles.div3}><p>{deviceCounts["Vehicle"] || 0}</p></div>
                                    </div>
                                    <div className={styles.chargingLoadReducedWrapper}>
                                        <div className={styles.chargingLoadReduced}>
                                            <p>  Charging Load Reduced</p>
                                        
                                        </div>
                                        <div className={styles.kwh2}><p>{deviceCounts["Vehicle"] * 3 || 0} kWh</p></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                     
                    </div>

                </div>
            </section>

        </div>
    )
}
export default DispatchResource;