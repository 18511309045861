import React from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import PaginationComponent from '../component/Pagination';
import Loader from '../component/Loader';

import logoImg from "../Assets/images/logo.png"
import styles from "../Assets/css/electricvehical.module.css"
import { useEffect, useState } from 'react';
const VehicleDetails = () => {
    const connectVehiclelist = process.env.REACT_APP_BASE_URL + 'get/texture/device/?device_type=Vehicle';
    // const [is_dispacting, setIsDispacting] = useState(false);
    const dispatchclikApi = process.env.REACT_APP_BASE_URL + 'texture/device/dispatch/'
    const [deviceStatus, setDeviceStatus] = useState({});
    const [vehicleData, setvehicleData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    var [VehiclbtnValue = 'Dispatch', setVehiclbtnValue] = useState();
    var [btnvalue = 'Dispatching', setbtnValue] = useState();
    const [loading, setLoading] = useState({
        global: false,
        dispatchbtnn: false,
       dispatchingbtnn:false,
    });


    const [postsPerPage] = useState(10);
    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const RefreshThermostatData = async () => {
        try {
            setLoading(true); 

            const storedAccessToken = localStorage.getItem('accessToken');
            const response = await fetch(connectVehiclelist, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedAccessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch thermostat data');
            }

            const data = await response.json();
            console.log('Thermostat data:', data);
            setvehicleData(data);
            setLoading(false); 

        } catch (error) {
            setLoading(false); 

            console.error('Error fetching thermostat data:', error);
        }
    };
    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading({ ...loading, global: true });
                const storedAccessToken = localStorage.getItem('accessToken');
                if (!storedAccessToken) {
                    throw new Error('Access token not found');
                }

                const response = await fetch(connectVehiclelist, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${storedAccessToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                console.log('vehicle data:', data);
                setvehicleData(data);
                setLoading({ ...loading, global: false });
            }
            catch (error) {
                setLoading({ ...loading, global: false });
                console.error('Error fetching vehicle data:', error);
            }
        };
        fetchData();


    }, []);
    const handleDispatchClick = async (device) => {
        const storedAccessToken = localStorage.getItem('accessToken');
        try {
            setLoading({ ...loading, dispatchbtnn: true });
            const initialData = {
                "status": device.vpp_participations[0].is_dispatching ? 'disable' : 'enable',
                "global": false,
                "device_type": "Vehicle",
                "device_id": device.id,

                // "temperature": 3
            };
            const response = await fetch(dispatchclikApi, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${storedAccessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(initialData)
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data. Server returned ' + response.status);
            }
            const data = await response.json();
            console.log('Dataaaa', data);
            RefreshThermostatData()
            // const updatedData = vehicleData.map(x => {
            //     if (x.id === device.id) {
            //         return {
            //             ...device,
            //             vpp_participations: [
            //                 {
            //                     ...device.vpp_participations[0],
            //                     is_dispatching: true,
            //                     status: "disable"
            //                 }],
            //         }
            //     } else {
            //         return x;
            //     }
            // });
            // setvehicleData(updatedData);

            // setVehiclbtnValue(VehiclbtnValue = "Dispatching");

            
            setDeviceStatus(prevStatus => ({
                ...prevStatus,
                [`dispatching_${device.id}`]: !prevStatus[`dispatching_${device.id}`]
            }));
            setLoading({ ...loading, dispatchbtnn: false }); 
            console.log('After state update', deviceStatus);
        } catch (error) {
            setLoading({ ...loading, dispatchbtnn: false }); 
            console.error('Error:', error.message);
        }
    };
    const handleDispatchingClick = async (device) => {
        const storedAccessToken = localStorage.getItem('accessToken');
        console.log(device, "id")
        try {
            setLoading({ ...loading, dispatchingbtnn: true });
            const initialData = {
                "status": device.vpp_participations[0].is_dispatching ? 'disable' : 'enable',
                "global": false,
                "device_type": "Vehicle",
                "device_id": device.id,

                // "temperature": 3
            };
            const response = await fetch(dispatchclikApi, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${storedAccessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(initialData)
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data. Server returned ' + response.status);
            }
            const data = await response.json();
            console.log('Dataaaa', data);
            RefreshThermostatData();
            // const updatedData = vehicleData.map(x => {
            //     if (x.id === device.id) {
            //         return {
            //             ...device,
            //             vpp_participations: [
            //                 {
            //                     ...device.vpp_participations[0],
            //                     is_dispatching: false,
            //                     status: "enable"
            //                 }],
            //         }
            //     } else {
            //         return x;
            //     }
            // });
            // setvehicleData(updatedData);

            // console.log('thermostatData ing', updatedData);

           
            
            setDeviceStatus(prevStatus => ({
                ...prevStatus,
                [`dispatching_${device.id}`]: !prevStatus[`dispatching_${device.id}`]
            }));
            setLoading({ ...loading, dispatchingbtnn: false });
            console.log('After state update', deviceStatus);
        } catch (error) {
            setLoading({ ...loading, dispatchingbtnn: false });
            console.error('Error:', error.message);
        }
    };
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = vehicleData.slice(indexOfFirstPost, indexOfLastPost);



    return (
        <div className={styles.fleet_view_cantaint}>
            {loading && loading.global && <Loader />}


            <section className={styles.topbar}>
                <div className={styles.container}>
                    <img className={styles.topbar_logo} src={logoImg} alt="Topbar Logo" />
                </div>
            </section>

            <section className="thermostat-details electric-vehicle-details">
                <Container>
                    <h2 className={styles.main_title}>Electric Vehicle Details</h2>
                    <div className="table-responsive" style={{ marginTop: "25px", marginLeft: "-55px" }}>
                        <Table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>Device ID</th>
                                    <th className="text-center">Device Type</th>
                                    <th className="text-center">Device ZIP</th>
                                    <th className="text-center">Charge Limit</th>
                                    <th className="text-center">Current Mode</th>
                                    <th className="text-center">State of Charge</th>
                                    <th className="text-center">Charging Load Reduced</th>
                                    <th className="text-center">Dispatching?</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosts.map((device, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{device.external_device_id}</td>
                                        <td className="text-center">{device.device_type}</td>
                                        <td className="text-center">{device.address && device.address.length > 0 ? device.address[0].postal_code : 'N/A'}</td>
                                        <td className="text-center">{device.device_state && device.device_state.length > 0 ? device.device_state[0].charge_limit + '%' : 'N/A'}</td>
                                        <td className="text-center">{device.device_state && device.device_state.length > 0 ? device.device_state[0].charging_state : 'N/A'}</td>
                                        <td className="text-center">{device.device_state && device.device_state.length > 0 ? device.device_state[0].charge_percentage + '%' : 'N/A' }</td>
                                        <td className="text-center">{device.vpp_participations && device.vpp_participations.length > 0 && device.vpp_participations[0].is_dispatching ? '5 kWh' : ''}</td>
                                       
                                        
                                        <td className="text-center">
                                            {device.vpp_participations && device.vpp_participations.length > 0 ? (
                                                device.vpp_participations[0].status ? (
                                                    device.vpp_participations[0].is_dispatching ? (
                                          <>
                                                        { loading && loading.dispatchingbtnn && <Loader />}
                                                        <button className={styles.buttondispaching} onClick={() => handleDispatchingClick(device)} style={{ backgroundColor: btnvalue === 'Dispatch' ? 'rgba(94, 248, 132, 1)' : '', color: btnvalue === 'Dispatch' ? 'black' : '' }}>{btnvalue}</button>
                                                        </>) : (<>
                                           
                                            {loading && loading.dispatchbtnn && <Loader />}
                                                        <button className={styles.dispachbtn} onClick={() => handleDispatchClick(device)}
                                                                style={{ backgroundColor: VehiclbtnValue === 'Dispatching' ? 'white' : '', color: VehiclbtnValue === 'Dispatching' ? 'blue' : '' }}
                                                            > {VehiclbtnValue}</button>
                                                        </>)
                                                ) : (
                                                    <button className={styles.dispachbtndisabled} disabled>Disabled</button>
                                                )
                                            ) : (
                                                <button className={styles.dispachbtndisabled} disabled>Disabled</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                           
                        </Table>
                    </div>
                    <PaginationComponent
                        currentPage={currentPage}
                        totalPages={Math.ceil(vehicleData.length / postsPerPage)}
                        onPageChange={onPageChange}
                    />
                </Container>
            </section>
        </div>
    );
};

export default VehicleDetails;
