import React from "react";
import styles from "../Assets/css/fleet.module.css";
import capicityForcast from "../Assets/images/capacity-forecast.png";
import Map from "./Map";
import mainlogo from "../Assets/images/logo.png";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../component/Loader";
import Chartapp from "../component/chart";
const Homepage = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [deviceCounts, setDeviceCounts] = useState({});
  const [franklinCount, setFranklinCount] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [thermostatData, setThermostatData] = useState([]);
  const [inverterData, setinverterData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  // const [deviceDetails, setDeviceDetails] = useState(null);
  const Navigate = useNavigate();
  const [data, setData] = useState([]);
  const [franklindata, setFranklinData] = useState([]);

  const [locations, setLocations] = useState([]);
  const connectListApi = process.env.REACT_APP_BASE_URL + "get/texture/device/";
  const franklinApi = process.env.REACT_APP_BASE_URL + "get/fwh/device/";
  const [loading, setLoading] = useState(true);
  let capacity = 0;
  let currentlyDispatching = 0;
  let generatingCapacity = 0;
  const [dispatchingDeviceCounts, setdispatchingDeviceCounts] = useState({});

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("accessToken");
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetchData();
    }
  }, [accessToken]);
  // useEffect(() => {
  //     if (Object.keys(deviceCounts).length > 0) {
  //         handleFilterChange("1"); // Select 'All' by default when deviceCounts updates
  //     }
  // }, [deviceCounts]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(connectListApi, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const deviceCounts = {};
      data.forEach((device) => {
        const deviceType = device.device_type
          .toLowerCase()
          .replace(/\s+/g, "")
          .replace(/^(.)/, (match, p1) => p1.toUpperCase());
        deviceCounts[deviceType] = (deviceCounts[deviceType] || 0) + 1;
        if (
          device.vpp_participations &&
          device.vpp_participations.length > 0 &&
          device.vpp_participations[0].is_dispatching === true
        ) {
          dispatchingDeviceCounts[deviceType] =
            (dispatchingDeviceCounts[deviceType] || 0) + 1;
        }
      });
      console.log(dispatchingDeviceCounts);
      setdispatchingDeviceCounts(dispatchingDeviceCounts);
      setDeviceCounts(deviceCounts);
      setData(data);
      setLoading(false);
      console.log(data, "data");
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const fetchFranklinData = async () => {
      try {
        const response = await fetch(franklinApi, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        // Log the entire response
        // console.log("Response status:", response.status);

        if (!response.ok) {
          throw new Error(
            `Failed to fetch Franklin data, status: ${response.status}`
          );
        }

        const franklinData = await response.json();
        setFranklinData(franklinData);
        // console.log("Franklin Data received:", franklinData);

        if (Array.isArray(franklinData)) {
          setFranklinCount(franklinData.length);
        } else {
          console.error("Expected an array, but got:", franklinData);
          setFranklinCount(0);
        }
      } catch (error) {
        console.error("Error fetching Franklin data", error);
        setFranklinCount(0);
      }
    };

    fetchFranklinData();
  }, [accessToken]);

  const combinedDeviceCounts = {
    ...deviceCounts,
    franklinwh: franklinCount !== null ? franklinCount : 0,
  };
  // const handleDetailsClick = (deviceType) => {
  //   console.log("Clicked on device type:", deviceType);

  //   const selectedDeviceData = data.find(
  //     (device) =>
  //       device.device_type
  //         .toLowerCase()
  //         .replace(/\s+/g, "")
  //         .replace(/^(.)/, (match, p1) => p1.toUpperCase()) === deviceType
  //   );
  //   if (selectedDeviceData) {
  //     console.log("Selected Device Data:", selectedDeviceData);
  //     setSelectedDevice(selectedDeviceData);

  //     if (deviceType.toLowerCase() === "inverter") {
  //       Navigate(`/InverterDetails`);
  //     } else if (deviceType.toLowerCase() === "thermostat") {
  //       Navigate(`/ThermostatDetails`);
  //     } else if (deviceType.toLowerCase() === "vehicle") {
  //       Navigate(`/VehicleDetails`);
  //     } else if (deviceType.toLowerCase() === "franklin") {
  //       Navigate(`/FranklinDetails`);
  //     } else {
  //       console.error(
  //         `Device details not found for device type: ${deviceType}`
  //       );
  //     }
  //   } else {
  //     console.error(`Device details not found for device type: ${deviceType}`);
  //   }
  // };
  const handleDetailsClick = (deviceType) => {
    console.log("Clicked on device type:", deviceType);

    // Normalize the device type for comparison
    const normalizedDeviceType = deviceType.toLowerCase().replace(/\s+/g, "");

    // Find the selected device data
    const selectedDeviceData = data.find((device) => {
      return (
        device.device_type.toLowerCase().replace(/\s+/g, "") ===
        normalizedDeviceType
      );
    });

    // If device data is found, set it in the state
    if (selectedDeviceData) {
      console.log("Selected Device Data:", selectedDeviceData);
      setSelectedDevice(selectedDeviceData);
    } else {
      console.error(`Device details not found for device type: ${deviceType}`);
    }

    // Navigate based on the normalized device type
    switch (normalizedDeviceType) {
      case "inverter":
        Navigate(`/InverterDetails`);
        break;
      case "thermostat":
        Navigate(`/ThermostatDetails`);
        break;
      case "vehicle":
        Navigate(`/VehicleDetails`);
        break;
      case "franklinwh":
        Navigate(`/FranklinDetails`);
        break;
      default:
        console.error(`No navigation found for device type: ${deviceType}`);
        break;
    }
  };

  if (deviceCounts["Thermostat"]) {
    capacity += deviceCounts["Thermostat"] * 0.25;
  }
  if (deviceCounts["Battery"]) {
    capacity += deviceCounts["Battery"] * 3;
  }
  if (deviceCounts["Vehicle"]) {
    capacity += deviceCounts["Vehicle"] * 3;
  }

  if (dispatchingDeviceCounts["Thermostat"]) {
    currentlyDispatching += dispatchingDeviceCounts["Thermostat"] * 0.25;
  }
  if (dispatchingDeviceCounts["Battery"]) {
    currentlyDispatching += dispatchingDeviceCounts["Battery"] * 3;
  }
  if (dispatchingDeviceCounts["Vehicle"]) {
    currentlyDispatching += dispatchingDeviceCounts["Vehicle"] * 3;
  }
  generatingCapacity = deviceCounts["Inverter"];

  console.log(generatingCapacity);
  const handleFilterChange = async (selectedDeviceType) => {
    if (selectedDeviceType === "All") {
      console.log("Selected Device Type:", selectedDeviceType);

      fetchData();
      setLocations(
        data.map((device) => ({ lat: device.latitude, lng: device.longitude }))
      );
    }
    if (selectedDeviceType === "Thermostat") {
      try {
        const connectThermolist = process.env.REACT_APP_THERMOSTAT_URL;
        const storedAccessToken = localStorage.getItem("accessToken");
        if (!storedAccessToken) {
          throw new Error("Access token not found");
        }
        const response = await fetch(connectThermolist, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${storedAccessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Thermostat data:", data);

        setThermostatData(data);
      } catch (error) {
        console.error("Error fetching Thermostat data:", error);
      }
    }
    if (selectedDeviceType === "Inverter") {
      try {
        const connectInverterlist = process.env.REACT_APP_INVERTER_URL;
        const storedAccessToken = localStorage.getItem("accessToken");
        if (!storedAccessToken) {
          throw new Error("Access token not found");
        }
        const response = await fetch(connectInverterlist, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${storedAccessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Inverterlist:", data);
        setinverterData(data);
      } catch (error) {
        console.error("Error fetching inveter data:", error);
      }
    }
    if (selectedDeviceType === "Vehicle") {
      try {
        const connectVehiclelist = process.env.REACT_APP_VEHICLE_URL;
        const storedAccessToken = localStorage.getItem("accessToken");
        if (!storedAccessToken) {
          throw new Error("Access token not found");
        }
        const response = await fetch(connectVehiclelist, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${storedAccessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("Inverterlist:", data);
        setVehicleData(data);
      } catch (error) {
        console.error("Error fetching Vehcile data:", error);
      }
    } else {
      const filteredData = data.filter(
        (device) =>
          device.device_type.toLowerCase().replace(/\s+/g, "") ===
          selectedDeviceType.toLowerCase()
      );
      console.log("Filtered Data:", filteredData);
      setData(filteredData);
    }
  };

  return (
    <div className={styles.fleet_view_cantaint}>
      {loading && loading && <Loader />}
      <section className={styles.topbar}>
        <div className={styles.container}>
          <img
            className={styles.topbar_logo}
            src={mainlogo}
            alt="Topbar Logo"
          />
        </div>
      </section>

      <section className={styles.fleet_view}>
        <div className={styles.container}>
          <div className="row">
            <div className="col-md-12">
              <h2 className={styles.main_title}>Fleet View</h2>
            </div>

            <div className="col-md-6">
              <div className={styles.fleet_view_left}>
                <div className={styles.location_filter}>
                  <Map data={data} franklinData={franklindata} />
                </div>
                <div className={styles.device_counts}>
                  <div className={styles.tableUpper}>
                    {" "}
                    <h4>Device</h4> <h4 className={styles.countss}>Counts</h4>
                    <h4 className={styles.details}>Details</h4>
                  </div>
                  <div className="table-responsive">
                    {/* <table className={styles.table} style={{ width: "100%" }}>
                      <tbody>
                        {deviceCounts &&
                          Object.entries(deviceCounts).map(
                            ([deviceType, count], index) => (
                              <tr key={index} className={styles.tableTR}>
                                <td className={styles.devicetype}>
                                  {deviceType === "Inverter"
                                    ? "Solar"
                                    : deviceType === "Vehicle"
                                    ? "Electric Vehicle"
                                    : deviceType}
                                </td>

                                <td className={styles.Counttd}>{count}</td>
                                <td
                                  className={styles.details}
                                  onClick={() => handleDetailsClick(deviceType)}
                                >
                                  Details
                                </td>
                              </tr>
                            )
                          )}

                        <tr className={styles.tableborder}></tr>
                        <tr className={styles.tableTR}>
                          <td style={{ width: "55%" }}>
                            <b>Total</b>
                          </td>
                          <td
                            style={{ width: "50%" }}
                            className={styles.countin}
                          >
                            <b>
                              {Object.values(deviceCounts).reduce(
                                (total, count) => total + count,
                                0
                              )}
                            </b>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table> */}
                    <table className={styles.table} style={{ width: "100%" }}>
                      <tbody>
                        {combinedDeviceCounts &&
                          Object.entries(combinedDeviceCounts).map(
                            ([deviceType, count], index) => (
                              <tr key={index} className={styles.tableTR}>
                                <td className={styles.devicetype}>
                                  {deviceType === "Inverter"
                                    ? "Solar"
                                    : deviceType === "Vehicle"
                                    ? "Electric Vehicle"
                                    : deviceType}
                                </td>

                                <td className={styles.Counttd}>{count}</td>
                                <td
                                  className={styles.details}
                                  onClick={() => handleDetailsClick(deviceType)}
                                >
                                  Details
                                </td>
                              </tr>
                            )
                          )}

                        <tr className={styles.tableborder}></tr>
                        <tr className={styles.tableTR}>
                          <td style={{ width: "55%" }}>
                            <b>Total</b>
                          </td>
                          <td
                            style={{ width: "50%" }}
                            className={styles.countin}
                          >
                            <b>
                              {Object.values(combinedDeviceCounts).reduce(
                                (total, count) => total + count,
                                0
                              )}
                            </b>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.fleet_view_left}>
                <div className={styles.dispatch_available_capacity}>
                  <h3>
                    Dispatch fleet available capacity:
                    <span className={styles.dispatch}>
                      <Link
                        className={styles.dispatchLink}
                        to="/DispatchResource"
                      >
                        Dispatch
                      </Link>
                    </span>
                  </h3>
                  <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-6">
                      <div className={styles.card_box}>
                        <p>
                          <b>Flexible Capacity</b>
                          <br />
                          <span className={styles.kwh}> {capacity} kWh</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-6">
                      <div className={styles.card_box}>
                        <p>
                          <b>Generating Capacity</b>
                          <br />
                          <span className={styles.kwh}>
                            {generatingCapacity} kWh
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-6">
                      <div className={styles.card_box}>
                        <p>
                          <b>Currently Dispatching</b>
                          <br />
                          <span className={styles.kwh}>
                            {currentlyDispatching} kWh
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.dispatchable_forecarst}>
                  <h4>Dispatchable Capacity Forecast</h4>
                  {/* <img className={styles.capicityforcast} src={capicityForcast} alt="Capacity Forecast" /> */}
                  <Chartapp />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Homepage;
