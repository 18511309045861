import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import mainlogo from "../Assets/images/logo.png";
import PaginationComponent from "../component/Pagination"; // Import PaginationComponent
import styles from "../Assets/css/Batterydetail.module.css";

const FranklinDetails = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20; // Define how many posts per page you want
  const franklinApi = process.env.REACT_APP_BASE_URL + "get/fwh/device/";

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const fetchDeviceData = async () => {
      try {
        const response = await fetch(franklinApi, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        const data = await response.json();
        setDevices(data); // Assuming 'data' is the array of devices
      } catch (error) {
        console.error("Error fetching device data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceData();
  }, [franklinApi]);

  // Calculate pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = devices.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(devices.length / postsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={styles.fleet_view_cantaint}>
      <section className={styles.topbar}>
        <div className={styles.container}>
          <img
            className={styles.topbar_logo}
            src={mainlogo}
            alt="Topbar Logo"
          />
        </div>
      </section>

      <section className="thermostat-details battery-details">
        <Container>
          <h2 className={styles.main_title}>Franklin Details</h2>
          <div
            className="table-responsive"
            style={{ marginTop: "25px", marginLeft: "-55px" }}
          >
            <Table className={styles.table}>
              <thead>
                <tr>
                  <th>Device ID</th>
                  {/* <th className="text-center">Device Type</th> */}
                  <th className="text-center">Device ZIP</th>
                  <th className="text-center">aGate</th>
                  <th className="text-center">aPower</th>
                  {/* <th className="text-center">State of Charge</th> */}
                  {/* <th className="text-center">latitude</th>
                  <th className="text-center">longitude</th> */}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : (
                  currentPosts.map((device) => (
                    <tr key={device.deviceID}>
                      <td>{device.id}</td>
                      {/* <td className="text-center">Franklin</td> */}
                      <td className="text-center">{device.zip_code}</td>
                      <td className="text-center">{device.agate}</td>
                      <td className="text-center">{device.apower}</td>
                      {/* <td className="text-center">{device.latitude}</td>
                      <td className="text-center">{device.longitude}</td> */}
                      {/* <td className="text-center">
                        {device.chargingLoadReduced}
                      </td> */}
                      {/* <td className="text-center">
                        <button
                          className={
                            device.dispatching
                              ? styles.buttondispaching
                              : styles.buttondisabled
                          }
                        >
                          <span className={styles.dispacthbutton}>
                            {device.dispatching ? "Dispatching" : "Disabled"}
                          </span>
                        </button>
                      </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Container>
      </section>
    </div>
  );
};

export default FranklinDetails;
