// /* eslint-disable no-undef */
// import React, { useEffect, useRef, useState } from "react";
// import styles from "../Assets/css/fleet.module.css";

// import { Loader } from "@googlemaps/js-api-loader";

// const Map = ({ data }) => {
//   const [selectedDeviceType, setSelectedDeviceType] = useState("All");
//   const mapContainerRef = useRef(null);
//   const [map, setMap] = useState(null);
//   const [markers, setMarkers] = useState([]);
//   const [infoWindow, setInfoWindow] = useState(null);

//   const handleSelectChange = (event) => {
//     const selectedValue = event.target.value;
//     setSelectedDeviceType(selectedValue);
//   };

//   useEffect(() => {
//     const loader = new Loader({
//       apiKey: "AIzaSyCZJuWuLmW8yAeMqVdBQAk2YhMFX-W0gSI",
//       version: "weekly",
//     });

//     loader.load().then(() => {
//       const newMap = new google.maps.Map(mapContainerRef.current, {
//         center: { lat: 41.5, lng: -100.0 },
//         zoom: 4,
//       });
//       setMap(newMap);

//       const newInfoWindow = new google.maps.InfoWindow();
//       setInfoWindow(newInfoWindow);
//     });
//   }, []);

//   useEffect(() => {
//     if (map && infoWindow) {
//       const getColor = (deviceType) => {
//         switch (deviceType.toLowerCase()) {
//           case "thermostat":
//             return "blue";
//           case "battery":
//             return "red";
//           case "vehicle":
//             return "green";
//           default:
//             return "orange";
//         }
//       };
//       const filteredData =
//         selectedDeviceType === "All"
//           ? data.filter(
//               (device) =>
//                 device.address[0]?.latitude && device.address[0]?.longitude
//             )
//           : data.filter(
//               (device) =>
//                 device.device_type
//                   .toLowerCase()
//                   .replace(/\s+/g, "")
//                   .replace(/^(.)/, (match, p1) => p1.toUpperCase()) ===
//                   selectedDeviceType &&
//                 device.address[0]?.latitude &&
//                 device.address[0]?.longitude
//             );
//       const locations = filteredData.map((device) => ({
//         device_name: device.name,
//         lat: parseFloat(device.address[0]?.latitude),
//         lng: parseFloat(device.address[0]?.longitude),
//         color: getColor(device.device_type),
//       }));

//       console.log(locations);
//       markers.forEach((marker) => {
//         marker.setMap(null); // Remove marker from the map
//       });
//       // Function to add markers
//       const addMarkers = (locations) => {
//         const newMarkers = locations.map((location, index) => {
//           const marker = new google.maps.Marker({
//             position: {
//               lat: parseFloat(location.lat),
//               lng: parseFloat(location.lng),
//             },
//             map: map,
//             icon: {
//               path: google.maps.SymbolPath.CIRCLE, // Shape of the marker
//               fillColor: location.color, // Color of the marker
//               fillOpacity: 1, // Opacity of the marker
//               strokeWeight: 0, // Stroke weight of the marker
//               scale: 10,
//             },
//             info: location.device_name, // Example info
//           });

//           marker.addListener("click", () => {
//             infoWindow.setContent(marker.info);
//             infoWindow.open(map, marker);
//             // map.setZoom(10);
//             map.setCenter(marker.getPosition()); // Center the map on the clicked marker
//           });

//           return marker;
//         });
//         setMarkers(newMarkers);
//       };
//       addMarkers(locations);
//     }
//   }, [map, infoWindow, data, selectedDeviceType]);

//   return (
//     <>
//       <select
//         className={styles.customs_elect}
//         value={selectedDeviceType}
//         onChange={handleSelectChange}
//       >
//         <option value="All">All</option>
//         {Array.from(
//           new Set(data.map((device) => device.device_type.toLowerCase()))
//         ).map((deviceType) => (
//           <option
//             key={deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}
//             value={deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}
//           >
//             {deviceType === "inverter"
//               ? "Solar"
//               : deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}
//           </option>
//         ))}
//         <option value="Franklin">Franklin</option>
//       </select>

//       <div ref={mapContainerRef} style={{ width: "100%", height: "393px" }} />
//     </>
//   );
// };

// export default Map;
/* eslint-disable no-undef */
/* eslint-disable no-undef */

import React, { useEffect, useRef, useState } from "react";
import styles from "../Assets/css/fleet.module.css";
import { Loader } from "@googlemaps/js-api-loader";

const Map = ({ data, franklinData }) => {
  const [selectedDeviceType, setSelectedDeviceType] = useState("All");
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [infoWindow, setInfoWindow] = useState(null);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDeviceType(selectedValue);
  };

  // Load Google Maps
  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyCZJuWuLmW8yAeMqVdBQAk2YhMFX-W0gSI",
      version: "weekly",
    });
    loader.load().then(() => {
      const newMap = new google.maps.Map(mapContainerRef.current, {
        center: { lat: 41.5, lng: -100.0 },
        zoom: 4,
      });
      setMap(newMap);
      const newInfoWindow = new google.maps.InfoWindow();
      setInfoWindow(newInfoWindow);
    });
  }, []);

  useEffect(() => {
    if (map && infoWindow) {
      const getColor = (deviceType) => {
        switch (deviceType?.toLowerCase()) {
          case "thermostat":
            return "blue";
          case "battery":
            return "red";
          case "vehicle":
            return "green";
          case "franklin":
            return "gray"; // Gray for Franklin devices
          default:
            return "orange"; // Default color
        }
      };

      let filteredData = [];

      // If "All" is selected, combine both `data` and `franklinData`
      if (selectedDeviceType === "All") {
        filteredData = [
          ...data.filter(
            (device) =>
              device.address &&
              Array.isArray(device.address) &&
              device.address.length > 0 &&
              !isNaN(parseFloat(device.address[0]?.latitude)) && // Validate lat
              !isNaN(parseFloat(device.address[0]?.longitude)) // Validate lng
          ),
          ...franklinData.filter(
            (device) =>
              !isNaN(parseFloat(device.latitude)) && // Ensure latitude is valid
              !isNaN(parseFloat(device.longitude)) // Ensure longitude is valid
          ),
        ];
      } else if (selectedDeviceType === "Franklin") {
        // Only show Franklin devices
        filteredData = franklinData.filter(
          (device) =>
            !isNaN(parseFloat(device.latitude)) && // Ensure latitude is valid
            !isNaN(parseFloat(device.longitude)) // Ensure longitude is valid
        );
      } else {
        // Filter based on the selected device type from `data`
        filteredData = data.filter((device) => {
          const deviceType = device.device_type;
          return (
            deviceType &&
            deviceType.toLowerCase().replace(/\s+/g, "") ===
              selectedDeviceType.toLowerCase() &&
            device.address &&
            Array.isArray(device.address) &&
            device.address.length > 0 &&
            !isNaN(parseFloat(device.address[0]?.latitude)) && // Validate lat
            !isNaN(parseFloat(device.address[0]?.longitude)) // Validate lng
          );
        });
      }

      const locations = filteredData.map((device) => {
        const lat =
          parseFloat(device.latitude) ||
          parseFloat(device.address[0]?.latitude) ||
          0;
        const lng =
          parseFloat(device.longitude) ||
          parseFloat(device.address[0]?.longitude) ||
          0;

        return {
          device_name:
            device.name || device.device_name || `Device ${device.id}`,
          lat,
          lng,
          color: getColor(
            device.device_type?.toLowerCase() === "franklin"
              ? "franklin"
              : device.device_type
          ),
        };
      });

      // Clear existing markers
      markers.forEach((marker) => {
        marker.setMap(null);
      });

      const addMarkers = (locations) => {
        const newMarkers = locations.map((location) => {
          const marker = new google.maps.Marker({
            position: {
              lat: location.lat,
              lng: location.lng,
            },
            map: map,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: location.color,
              fillOpacity: 1,
              strokeWeight: 0,
              scale: 10,
            },
            info: location.device_name,
          });

          marker.addListener("click", () => {
            infoWindow.setContent(marker.info);
            infoWindow.open(map, marker);
            map.setCenter(marker.getPosition());
          });

          return marker;
        });
        setMarkers(newMarkers);
      };

      addMarkers(locations);
    }
  }, [map, infoWindow, data, selectedDeviceType, franklinData]);

  return (
    <>
      <select
        className={styles.customs_elect}
        value={selectedDeviceType}
        onChange={handleSelectChange}
      >
        <option value="All">All</option>
        {Array.from(
          new Set(data.map((device) => device.device_type?.toLowerCase() || ""))
        ).map((deviceType) => (
          <option
            key={deviceType}
            value={deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}
          >
            {deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}
          </option>
        ))}
        <option value="Franklin">Franklin</option>
      </select>

      <div ref={mapContainerRef} style={{ width: "100%", height: "393px" }} />
    </>
  );
};

export default Map;
