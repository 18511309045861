
import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import styles from "../Assets/css/charts.module.css";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Chartapp = () => {
    const [dataPoints, setDataPoints] = useState([]);
    const [yAxisMax, setYAxisMax] = useState(1); 

    useEffect(() => {
        fetchChartData();
    }, []);

    const fetchChartData = async () => {
        const connectChartApi = process.env.REACT_APP_BASE_URL + 'fleet/predictions/?hours_ahead=24';

        try {
            const response = await fetch(connectChartApi);
            const data = await response.json();

            const newPoints = data.map(item => ({
                x: new Date(item.datetime),
                y: Math.abs(item.usages) 
            }));
            // console.log(newPoints,"njnjbinbj");
            const maxUsage = Math.max(...newPoints.map(point => point.y)); 
            const maxYAxisValue = maxUsage + 1; 
            setYAxisMax(maxYAxisValue);
            setDataPoints(newPoints);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const upcomingHour = (currentHour + 2) % 24;
   
    const options = {
        animationEnabled: true,
      
        axisY: {
            minimum: 0,
            maximum: yAxisMax,
            interval: 1,
            lineThickness: 0,
            gridThickness: 0,
            labelFontColor: "rgba(21, 4, 158, 1)",
            title: "kWh", 
            titleFontColor: "rgba(21, 4, 158, 1)",
            labelFormatter: function (e) {
                return Math.abs(e.value);
            }
        },

axisX: {
    labelFontColor: "rgba(21, 4, 158, 1)",
    // minimum: 0,
            type: "time",
            time: {
                unit: "hour",
                stepSize: 2,
                min: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentHour).getTime(),
                max: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), upcomingHour).getTime(),
                displayFormats: {
                    hour: 'HH:mm'
                }
    },
    

    ticks: {
        callback: function (value, index, values) {
            const hour = value.getHours() % 24 || 24;
            return `${String(hour).padStart(2, '0')}:00`;
        }
    }
        },
        data: [{
            type: "line",
            xValueFormatString: "HH:mm",
            yValueFormatString: "0.##", 
            toolTipContent: " {y} kWh",
            dataPoints: dataPoints
        }]
    };

    return (
        <div className={styles.charts}>
            <CanvasJSChart options={options} />
        </div>
    );
}

export default Chartapp;
