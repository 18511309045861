
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Homepage from './pages/Home';
import ThermostatDetails from './pages/Thermostats';
import InverterDetails from './pages/ElectricalVehical';
import { useState } from 'react';
import DispatchResource from './pages/DispatchResources';
import VehicleDetails from './pages/vehical';
import FranklinDetails from './pages/franklinDetails';

function App() {
  const [data, setData] = useState(null);
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login/>}></Route>
        <Route path="/Home" element={<Homepage />}></Route>
        <Route path="/DispatchResource" element={<DispatchResource />}></Route>
        <Route path="/ThermostatDetails" element={<ThermostatDetails data={data} />}></Route>
        <Route path="/InverterDetails" element={<InverterDetails />}></Route>

        <Route path="/VehicleDetails" element={<VehicleDetails />}></Route>
        <Route path="/FranklinDetails" element={<FranklinDetails />}></Route>

        
    </Routes>
    
    </BrowserRouter>

  );
}

export default App;
