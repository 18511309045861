import React from 'react';
import styles from "../Assets/css/fleet.module.css"
import topbarLogo from '../Assets/images/logo.png';
import { useEffect, useState } from "react";
import { LOGIN_URL } from '../ApiConfig';
import { useNavigate } from 'react-router-dom';
import Loader from "../component/Loader"



const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true); 

    const connectLoginApi = process.env.REACT_APP_BASE_URL + "admin/login/"
    useEffect(() => {
        const storedAccessToken = localStorage.getItem("accessToken");
        if (storedAccessToken) {
            Navigate('/Home');
        }
      }, []);
    const handleLoginSuccess = (access, refresh) => {
        // console.log("Accesstoken",access)
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);


        Navigate('/Home');
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            setError('Please enter email and password');
            return;
        }
      
        setIsLoading(true);

        try {
            setLoading(true); 
            const response = await fetch(connectLoginApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }   

            const responseData = await response.json();
            console.log('Login successful:', responseData);



            handleLoginSuccess(responseData.access, responseData.refresh);
            setLoading(false); 
        } catch (error) {
            setError(error.message);
            setLoading(false); 
        }

        setIsLoading(false);
        setEmail('');
        setPassword('');
        
    };

    return (
        <div className={styles.fleet_view_cantaint_login}>
            <section className={styles.topbar}>
                <div className={styles.container}>
                    <img className={styles.topbar_logo} src={topbarLogo} alt="Logo" />
                </div>
            </section>
                <section className={styles.login_content}>
                    <div className="container">
                        <div className={styles.card}>
                            <h2 className="text-center">Login</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group" style={{marginBottom:"20px"}}>
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control" style={{ height: "45px", color: "blue" }} id="exampleInputEmail1" name="email" aria-describedby="emailHelp" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="form-group" style={{ marginBottom: "20px" }}>
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control" style={{ height: "45px" }} id="exampleInputPassword1" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                {error && <p className="text-danger">{error}</p>}
                                {/* <p className={styles.forgot_password}><a href="#">Forgot password</a></p> */}
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
            </section>
        </div>
    );
}


export default Login;
