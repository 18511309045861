// Loader.js

import React from 'react';
import LoaderImg from '../Assets/images/loader.gif';
import styles from '../Assets/css/loader.module.css';

const Loader = () => {
    return (
        <div className={styles.loader}>
            <img src={LoaderImg} alt="Loading..." />
        </div>
    );
};

export default Loader;
